import createAction from '../../common/services/create-action';
import {
  POST_REMOVE_SOLVED_COMMENT,
  POST_REMOVE_TOP_COMMENT,
} from '../components/messages/message-types';
import { showMessage } from '../../common/messages/framework/store/message-actions';
import { isExperimentEnabled } from '../selectors/experiments-selectors';
import { EXPERIMENT_MARK_AS_SOLVED } from '../constants/experiments';

export const REMOVE_TOP_COMMENT_REQUEST = 'post/REMOVE_TOP_COMMENT_REQUEST';
export const REMOVE_TOP_COMMENT_SUCCESS = 'post/REMOVE_TOP_COMMENT_SUCCESS';
export const REMOVE_TOP_COMMENT_FAILURE = 'post/REMOVE_TOP_COMMENT_FAILURE';

const removeTopCommentRequest = createAction(REMOVE_TOP_COMMENT_REQUEST);
const removeTopCommentSuccess = createAction(REMOVE_TOP_COMMENT_SUCCESS);
const removeTopCommentFailure = createAction(REMOVE_TOP_COMMENT_FAILURE);

export function removeTopComment(postId) {
  return (dispatch, getState, { request }) => {
    dispatch(removeTopCommentRequest());

    const promise = request.delete(`/posts/${postId}/top-comment`);
    return promise
      .then(
        post => dispatch(removeTopCommentSuccess(post)),
        () => dispatch(removeTopCommentFailure()),
      )
      .then(() => promise);
  };
}

export function removeTopCommentAndNotify(postId) {
  return (dispatch, getState) =>
    dispatch(removeTopComment(postId)).then(() =>
      dispatch(
        showMessage(
          isExperimentEnabled(getState(), EXPERIMENT_MARK_AS_SOLVED)
            ? POST_REMOVE_SOLVED_COMMENT
            : POST_REMOVE_TOP_COMMENT,
        ),
      ),
    );
}
