import { addErrorState } from './debug-state-actions';

export function handleError({ store, setProps, appParams, captureToSentry }) {
  return error => {
    const state = store && store.getState();
    console.error('--- error in createForumAppController ---', error);
    if (captureToSentry) {
      if (
        (error instanceof Response || (error.url && error.status && error.statusText)) &&
        error.status >= 500
      ) {
        captureToSentry(
          Error(`Failed to fetch ${error.url} with status ${error.status} - ${error.statusText}`),
        );
      } else {
        captureToSentry(error);
      }
    }
    setProps({
      state,
      cssBaseUrl: appParams.baseUrls.staticsBaseUrl,
      workerError: error.toString(),
      wasWorkerErrorCaptured: captureToSentry !== undefined,
    });
    store && store.dispatch(addErrorState(error));
  };
}
