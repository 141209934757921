import { fetchSearchWithCancel } from './fetch-search';
import createAction from '../../common/services/create-action';

export const SEARCH_CLEAR_RESULTS = 'search/CLEAR_RESULTS';

export const MIN_QUERY_LENGTH = 2;

export const clearSearchResults = createAction(SEARCH_CLEAR_RESULTS);

export default function search({ query = '', sort, minQueryLength = MIN_QUERY_LENGTH }) {
  return dispatch => {
    const promise = dispatch(fetchSearchWithCancel({ query, sort, debounce: 300, minQueryLength }));
    if (query.length < minQueryLength) {
      dispatch(clearSearchResults());
    }
    return promise;
  };
}
