import {
  SANTA_MEMBERS_APP_ID,
  MEMBER_INFO_APP_ID,
} from '@wix/communities-universal/dist/src/constants/appsConfig';

export const SET_COMMUNITIES_CONTEXT = 'communitiesContext/SET';

export const isMaInstalled = wixCodeApi =>
  Promise.resolve(
    wixCodeApi.site.isAppSectionInstalled({
      appDefinitionId: MEMBER_INFO_APP_ID,
      sectionId: 'member_info',
    }),
  );

export const setCommunitiesContext = () => (dispatch, getState, { wixCodeApi }) => {
  isMaInstalled(wixCodeApi).then(isInstalled => {
    dispatch({
      type: SET_COMMUNITIES_CONTEXT,
      payload: {
        [SANTA_MEMBERS_APP_ID]: {
          isInstalled,
        },
      },
    });
  });
};
