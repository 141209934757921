import uri from 'urijs';
import createAction from '../../common/services/create-action';
import { buildPaginationRequestParams } from '../services/pagination';
import { getCategoryPageSorting } from '../selectors/sorting-selectors';
import { getSortQueryParams } from '../constants/sorting';
import { getHeader } from '../services/get-header';

export const FETCH_POSTS_REQUEST = 'posts/FETCH_REQUEST';
export const FETCH_POSTS_SUCCESS = 'posts/FETCH_SUCCESS';
export const FETCH_POSTS_FAILURE = 'posts/FETCH_FAILURE';

export const fetchPostsRequest = createAction(
  FETCH_POSTS_REQUEST,
  payload => payload,
  (payload, meta) => meta,
);
export const fetchPostsSuccess = createAction(
  FETCH_POSTS_SUCCESS,
  payload => payload,
  (payload, meta) => meta,
);
export const fetchPostsFailure = createAction(
  FETCH_POSTS_FAILURE,
  payload => payload,
  (payload, meta) => meta,
);

export default function fetchPosts({ categoryId, page, pageSize, sort, postType }) {
  return (dispatch, getState, { request }) => {
    dispatch(fetchPostsRequest(undefined, { categoryId }));
    const sorting = sort || getCategoryPageSorting(getState());
    const sortingConfig = getSortQueryParams(sorting);

    const params = buildPaginationRequestParams(page, pageSize);
    const promise = request(
      uri('/posts').query({
        ...(categoryId ? { categoryId } : {}),
        ...params,
        ...sortingConfig,
        postType,
      }),
      { parseHeaders: true },
    );

    return promise
      .then(
        ({ body = [], headers = {} }) =>
          dispatch(
            fetchPostsSuccess(body, {
              sort: sorting,
              categoryId,
              page,
              entityCount: parseInt(getHeader(headers, 'Wix-Forum-TotalResults'), 10),
            }),
          ),
        () => dispatch(fetchPostsFailure(undefined, { categoryId })),
      )
      .then(() => promise);
  };
}
