export const SHOW_NEW_POST_MESSAGE = 'newPostMessage/SHOW';
export const SHOW_NEW_POST_MESSAGE_DEBOUNCED = 'newPostMessage/SHOW_DEBOUNCED';

export const showNewPostMessage = payload => debounce =>
  debounce({
    type: SHOW_NEW_POST_MESSAGE,
    payload,
  });

export const showNewPostMessageDebounced = payload => {
  const action = showNewPostMessage(payload);
  action.type = SHOW_NEW_POST_MESSAGE_DEBOUNCED;
  action.meta = {
    debounce: 0,
  };
  return action;
};
