import {
  CATEGORY_TYPE_MEMBERS_ONLY,
  CATEGORY_TYPE_PRIVATE,
  CATEGORY_TYPE_PUBLIC,
} from '../../app/constants/category-types';
import { TEMPLATE_CATEGORY_GROUP_ID } from '../../app/constants/categories-manager';

export function isPrivate(category) {
  return category && category.type === CATEGORY_TYPE_PRIVATE;
}

export function isMembersOnly(category) {
  if (!category) {
    return;
  }
  return category.type ? category.type === CATEGORY_TYPE_MEMBERS_ONLY : category.isPrivate;
}

export function isPublic(category) {
  return category.type ? category.type === CATEGORY_TYPE_PUBLIC : !category.isPrivate;
}

export function hasCategoryGroup(category) {
  return (
    category.categoryGroupId !== TEMPLATE_CATEGORY_GROUP_ID &&
    category.categoryGroupId !== undefined
  );
}

export function isChangedFromPrivate(updatedCategory, initialCategory) {
  return !isPrivate(updatedCategory) && isPrivate(initialCategory);
}

export function didCategoryBecomeMoreRestricted(updatedCategory, initialCategory) {
  return (
    updatedCategory.type !== initialCategory.type &&
    !isPublic(updatedCategory) &&
    !isPrivate(initialCategory)
  );
}
