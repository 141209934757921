import { get, chain } from 'lodash';
import { getMembersGroups } from './members-groups-selectors';
import { getCategory } from './categories-selectors';
import {
  GROUP_TYPE_BADGE,
  GROUP_TYPE_ROLE,
  GROUP_TYPE_PAID_PLAN,
  GROUP_TYPE_MEMBER,
  GROUP_TYPE_BADGE_WITH_PERMISSION,
} from '../constants/manage-category-members';
import { hasCategoryGroup } from '../../common/services/category-privacy-utils';

export const getManageCategoryMembers = state => state.manageCategoryMembers;
export const getEntitiesCountsWithAccess = (state, categoryId) => {
  const category = getCategory(state, categoryId);
  const groups = category.groups || [];
  const allSitesGroups = chain(getMembersGroups(state))
    .sortBy(group => group.title)
    .groupBy(group => group.type)
    .value();
  const countAssignedGroups = groupType => {
    const siteGroupsOfType = allSitesGroups[groupType];
    if (siteGroupsOfType) {
      return groups.reduce((result, groupId) => {
        return siteGroupsOfType.some(g => g.id === groupId) ? result + 1 : result;
      }, 0);
    } else {
      return 0;
    }
  };

  const counters = {
    assigned: {
      siteMembers: getListOfSiteMembersToGiveAccessTo(state).length,
      badges:
        countAssignedGroups(GROUP_TYPE_BADGE) +
        countAssignedGroups(GROUP_TYPE_BADGE_WITH_PERMISSION),
      roles: countAssignedGroups(GROUP_TYPE_ROLE),
      plans: countAssignedGroups(GROUP_TYPE_PAID_PLAN),
    },
  };

  if (hasCategoryGroup(category) && allSitesGroups[GROUP_TYPE_MEMBER]) {
    const membersCountWithAccess = get(
      allSitesGroups[GROUP_TYPE_MEMBER].find(g => g.id === category.categoryGroupId),
      'membersCount',
      0,
    );
    counters.assigned.siteMembers =
      membersCountWithAccess +
      counters.assigned.siteMembers -
      getListOfSiteMembersToRemoveAccessFrom(state).length;
  }
  return counters;
};
export const getListOfSiteMembersToGiveAccessTo = state =>
  getManageCategoryMembers(state).addSiteMembersToCategoryGroup;
export const getListOfSiteMembersToRemoveAccessFrom = state =>
  getManageCategoryMembers(state).removeSiteMembersFromCategoryGroup;

export const getAllGroupsMembersCount = (state, categoryId) => {
  const category = getCategory(state, categoryId);
  const categoryGroups = (category.groups || []).filter(g => g !== category._id);
  const allGroups = getMembersGroups(state);
  return {
    paidPlanMemberCount: getGroupMembersCount(allGroups, categoryGroups, GROUP_TYPE_PAID_PLAN),
    badgeMemberCount: getGroupMembersCount(allGroups, categoryGroups, GROUP_TYPE_BADGE),
    roleMemberCount: getGroupMembersCount(allGroups, categoryGroups, GROUP_TYPE_ROLE),
  };
};

function getGroupMembersCount(allGroups, categoryGroups, type) {
  return allGroups
    .filter(g => g.type === type && g.membersCount && categoryGroups.includes(g.id))
    .reduce((acc, curr) => acc + curr.membersCount, 0);
}
