import { subscribeChannel } from './duplexer-actions';
import { joinChannel } from './ws-actions';
import { getChannel } from '../services/ws-channel';
import { DUPLEXER_CHANNELS } from '../../../universal/constants/duplexer';

export function emitForumOpened() {
  return dispatch => {
    dispatch(joinChannel({ channel: getChannel('forum') }));
    dispatch(subscribeChannel({ channelName: DUPLEXER_CHANNELS.FORUM }));
  };
}
