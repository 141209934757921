import { showMessage } from '../../common/messages/framework/store/message-actions';
import { POST_ENABLE_COMMENTING } from '../components/messages/message-types';

export const ENABLE_POST_COMMENTS_REQUEST = 'post/ENABLE_COMMENTS_REQUEST';
export const ENABLE_POST_COMMENTS_SUCCESS = 'post/ENABLE_COMMENTS_SUCCESS';
export const ENABLE_POST_COMMENTS_FAILURE = 'post/ENABLE_COMMENTS_FAILURE';

export function enablePostComments(id) {
  return (dispatch, getState, { request }) => {
    dispatch({
      type: ENABLE_POST_COMMENTS_REQUEST,
    });

    const promise = request.post(`/posts/${id}/enableComments`);

    return promise
      .then(post => {
        dispatch(showMessage(POST_ENABLE_COMMENTING));
        return dispatch({
          type: ENABLE_POST_COMMENTS_SUCCESS,
          payload: post,
        });
      })
      .catch(() =>
        dispatch({
          type: ENABLE_POST_COMMENTS_FAILURE,
        }),
      )
      .then(() => promise);
  };
}
