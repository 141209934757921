import {
  CATEGORIES_MANAGER_EDIT_CATEGORY,
  CATEGORIES_MANAGER_OPEN_MAIN_WINDOW,
  CATEGORIES_MANAGER_SHOW_LOADER,
  CATEGORIES_MANAGER_HIDE_LOADER,
} from '../actions/categories-manager-actions-constants';

const initialState = {
  categoryInEditId: null,
  categoryCopy: null,
  openPanels: [],
  showLoader: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CATEGORIES_MANAGER_EDIT_CATEGORY: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case CATEGORIES_MANAGER_OPEN_MAIN_WINDOW: {
      return {
        ...state,
        ...initialState,
      };
    }
    case CATEGORIES_MANAGER_SHOW_LOADER:
      return { ...state, showLoader: true };
    case CATEGORIES_MANAGER_HIDE_LOADER:
      return { ...state, showLoader: false };
    default:
      return state;
  }
}
