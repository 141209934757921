import { isArray } from 'lodash';

export const getMembersGroups = state => state.membersGroups;
export const getMembersGroupsByType = (state, type) => {
  const list = getMembersGroups(state).filter(group =>
    isArray(type) ? type.includes(group.type) : group.type === type,
  );
  const byId = list.reduce((result, g) => {
    result[g.id] = g;
    return result;
  }, {});

  return {
    list,
    byId,
    count: list.length,
  };
};
