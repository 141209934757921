import _ from 'lodash';
import {
  FETCH_COMMENTS_REQUEST,
  FETCH_COMMENTS_SUCCESS,
} from '../actions/fetch-comments-constants';
import { QUESTION } from '../constants/post-types';
import { FETCH_COMMENT_SUCCESS } from '../actions/fetch-comment';
import { CREATE_COMMENT_SUCCESS } from '../actions/create-comment';
import { UPDATE_COMMENT_SUCCESS } from '../actions/update-comment';
import { DELETE_COMMENT_SUCCESS } from '../actions/delete-comment';
import {
  INCREMENT_COMMENT_LIKE_COUNT_FAILURE,
  INCREMENT_COMMENT_LIKE_COUNT_REQUEST,
  INCREMENT_COMMENT_LIKE_COUNT_SUCCESS,
} from '../actions/increment-comment-like-count';
import { FETCH_POST_PAGE_DATA_SUCCESS } from '../actions/fetch-post-page-data';
import { CLEAR_COMMENTS } from '../actions/clear-comments';
import { VOTE_COMMENT_SUCCESS } from '../actions/vote-comment';

const isQuestion = postType => postType === QUESTION;

const comment = (state, action) => {
  if (state._id !== action.payload._id) {
    return state;
  }
  switch (action.type) {
    case INCREMENT_COMMENT_LIKE_COUNT_REQUEST:
      return {
        ...state,
        isLiked: !state.isLiked,
        likeCount: state.likeCount + (state.isLiked ? -1 : 1),
        isLikeInProgress: true,
      };
    case INCREMENT_COMMENT_LIKE_COUNT_SUCCESS:
      return _.omit({ ...state, ...action.payload }, 'isLikeInProgress');
    case INCREMENT_COMMENT_LIKE_COUNT_FAILURE:
      return _.omit(
        {
          ...state,
          isLiked: !state.isLiked,
          likeCount: state.likeCount + (state.isLiked ? -1 : 1),
        },
        'isLikeInProgress',
      );
    case VOTE_COMMENT_SUCCESS:
      const { isUpvoted, isDownvoted, upvoteCount, downvoteCount } = action.payload;
      return {
        ...state,
        isUpvoted,
        isDownvoted,
        upvoteCount,
        downvoteCount,
      };
    default:
      return state;
  }
};

const getSortBy = postType => {
  if (!isQuestion(postType)) {
    return 'createdDate';
  }
  return '';
};

const commentList = (state = [], { type, payload = {}, meta = {} } = {}) => {
  switch (type) {
    case FETCH_COMMENTS_SUCCESS:
      return _([...state, ...payload.comments])
        .unionBy('_id')
        .sortBy(getSortBy(payload.postType))
        .value();
    case FETCH_POST_PAGE_DATA_SUCCESS:
      return _([
        ...state,
        ...payload.result.comments.result,
        ..._.flatMap(payload.result.replies.result.map(r => r.replies)),
      ])
        .unionBy('_id')
        .sortBy(getSortBy(payload.result.post.postType))
        .value();
    case CREATE_COMMENT_SUCCESS:
      if (isQuestion(meta.postType)) {
        return [...state, payload];
      }
      return _([payload])
        .unionBy(state, '_id')
        .sortBy('createdDate')
        .value();
    case UPDATE_COMMENT_SUCCESS:
      if (isQuestion(meta.postType)) {
        return state.map(c => (c._id === payload._id ? payload : c));
      }
      return _([payload])
        .unionBy(state, '_id')
        .sortBy('createdDate')
        .value();
    case FETCH_COMMENT_SUCCESS:
      if (isQuestion(payload.postType)) {
        const comment = payload.comment;
        if (state.some(c => c._id === comment._id)) {
          return state.map(c => (c._id === comment._id ? comment : c));
        } else {
          return [...state, payload.comment];
        }
      }
      return _([payload.comment])
        .unionBy(state, '_id')
        .sortBy('createdDate')
        .value();
    case DELETE_COMMENT_SUCCESS:
      return state.filter(comment => comment._id !== payload._id);
    case INCREMENT_COMMENT_LIKE_COUNT_REQUEST:
    case INCREMENT_COMMENT_LIKE_COUNT_SUCCESS:
    case INCREMENT_COMMENT_LIKE_COUNT_FAILURE:
    case VOTE_COMMENT_SUCCESS:
      return state.map(current => comment(current, { type, payload }));
    case CLEAR_COMMENTS:
      return [];
    default:
      return state;
  }
};

export default function commentsReducer(state = {}, action) {
  const { type, payload } = action;
  const { postId } = payload || {};

  switch (type) {
    case FETCH_COMMENTS_REQUEST:
      return {
        [postId]: state[postId],
      };
    case FETCH_COMMENTS_SUCCESS:
    case FETCH_COMMENT_SUCCESS:
    case CREATE_COMMENT_SUCCESS:
    case UPDATE_COMMENT_SUCCESS:
    case DELETE_COMMENT_SUCCESS:
    case INCREMENT_COMMENT_LIKE_COUNT_REQUEST:
    case INCREMENT_COMMENT_LIKE_COUNT_SUCCESS:
    case INCREMENT_COMMENT_LIKE_COUNT_FAILURE:
    case CLEAR_COMMENTS:
    case VOTE_COMMENT_SUCCESS:
      return {
        [postId]: commentList(state[postId], action),
      };
    case FETCH_POST_PAGE_DATA_SUCCESS: {
      const postId = payload.result.post._id;
      return {
        [postId]: commentList(state[postId], action),
      };
    }
    default:
      return state;
  }
}
