import { subscribeChannel, unsubscribeChannel } from './duplexer-actions';
import { joinChannel, leaveChannel } from './ws-actions';
import { getChannel } from '../services/ws-channel';
import { DUPLEXER_CHANNELS } from '../../../universal/constants/duplexer';

export const emitOpenPost = postId => dispatch => {
  dispatch(joinChannel({ channel: getChannel('post', postId) }));
  dispatch(subscribeChannel({ channelName: DUPLEXER_CHANNELS.POST, resourceId: postId }));
};

export const emitClosePost = postId => dispatch => {
  dispatch(leaveChannel({ channel: getChannel('post', postId) }));
  dispatch(unsubscribeChannel({ channelName: DUPLEXER_CHANNELS.POST, resourceId: postId }));
};
