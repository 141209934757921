import { setIsLoading } from './set-is-loading';
import { addItemToUploadedRegistry } from './uploaded-registry-actions';

export const FETCH_OEMBED_REQUEST = 'oembed/REQUEST';
export const FETCH_OEMBED_SUCCESS = 'oembed/SUCCESS';
export const FETCH_OEMBED_FAILURE = 'oembed/FAILURE';

export const fetchOembedRequest = () => ({ type: FETCH_OEMBED_REQUEST });
export const fetchOembedSuccess = payload => ({ type: FETCH_OEMBED_SUCCESS, payload });
export const fetchOembedFailure = () => ({ type: FETCH_OEMBED_FAILURE });

export const fetchOembed = url => (dispatch, getState, { request }) => {
  dispatch(fetchOembedRequest());

  const promise = request(`/oembed?url=${url}`);

  return promise
    .then(details => {
      dispatch(
        addItemToUploadedRegistry({
          uploadId: url,
          uploadData: { ...details, _original_url: url, isSuccessful: true },
        }),
      );
      dispatch(fetchOembedSuccess(details));
    })
    .catch(() => {
      dispatch(
        addItemToUploadedRegistry({ video_url: url, _original_url: url, isSuccessful: false }),
      );
      dispatch(fetchOembedFailure());
    })
    .then(() => {
      // Needed for decoratePostFormProps
      const action = setIsLoading('oembed', Date.now(), false);
      action.meta = { debounce: 1 };
      dispatch(action);
    })
    .then(() => promise);
};
