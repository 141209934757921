import createBiMiddleware from '../middleware/bi-middleware';
import * as forumsUouEvents from './forums-uou-events';
import * as forumsUsersEvents from './forums-users-events';
import forumsUouSchemaLogger from '@wix/bi-logger-forums-uou';
import forumsUsersSchemaLogger from '@wix/bi-logger-forums-users';
import { parseInstance } from '../../common/store/instance-values/parse-instance';
import { FORUM_APP_ID } from '../constants/applications';

const createMiddleware = (platformAPIs, instanceId, wixCodeApi, schemaLogger, eventMap, config) => {
  const user = wixCodeApi.user.currentUser;
  const instance = parseInstance(user.instance) || {};
  const visitorId = platformAPIs.bi.visitorId || instance.uid;
  const options = {
    defaults: {
      biToken: platformAPIs.bi.biToken,
      app_id: FORUM_APP_ID,
      app_site_id: instanceId,
      instance_id: instanceId,
      visitor_id: visitorId,
      _msid: instance.metaSiteId,
      _appId: FORUM_APP_ID,
      _instanceId: instanceId,
      _visitorId: visitorId,
      _siteOwnerId: instance.siteOwnerId,
      _siteMemberId: user.loggedIn ? user.id : '',
      _viewMode: wixCodeApi.window.viewMode.toLowerCase(),
    },
  };
  const bi = schemaLogger(platformAPIs.biLoggerFactory())(options).bi;
  return createBiMiddleware({
    bi,
    eventMap,
    visitorId: platformAPIs.bi.visitorId,
    viewerSessionId: platformAPIs.bi.viewerSessionId,
    config,
  });
};

export const forumsUouBiMiddleware = (platformAPIs, instanceId, wixCodeApi, config) =>
  createMiddleware(
    platformAPIs,
    instanceId,
    wixCodeApi,
    forumsUouSchemaLogger,
    forumsUouEvents.eventMap,
    config,
  );

export const forumsUsersBiMiddleware = (platformAPIs, instanceId, wixCodeApi, config) =>
  createMiddleware(
    platformAPIs,
    instanceId,
    wixCodeApi,
    forumsUsersSchemaLogger,
    forumsUsersEvents.eventMap,
    config,
  );
