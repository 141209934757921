import { DELETE_COMMENT } from '../../../constants/interactions';
import { COMMENT_DELETE } from '../../messages/message-types';
import { deleteComment } from '../../../actions/delete-comment';
import { getRouteParams } from '../../../../common/router/router-selectors';
import { getPostBySlug } from '../../../selectors/post-selectors';
import { getEntityCount } from '../../../selectors/pagination-selectors';
import { getIsMobile } from '../../../../common/store/basic-params/basic-params-selectors';
import { getCommentsPerPage } from '../../../constants/pagination';
import { buildPageUrl, getLastPage } from '../../../services/pagination';
import { navigateWithinForum } from '../../../../common/actions/navigate-within-forum';

export const deleteCommentModalResolver = (payload, { showMessage }) => (
  dispatch,
  getState,
  { fedopsLogger },
) => {
  fedopsLogger.interactionStarted(DELETE_COMMENT);
  return dispatch(deleteComment(payload)).then(() => {
    const state = getState();
    const { page, categorySlug, postSlug } = getRouteParams(state);
    const currentPage = parseInt(page, 10) || 1;
    const post = getPostBySlug(state, postSlug) || {};
    const lastPage = getLastCommentPage(state, post);
    if (currentPage > lastPage) {
      dispatch(
        navigateWithinForum(buildPageUrl(categorySlug, post.slug, page > 1 ? page - 1 : page)),
      );
    }
    return showMessage(COMMENT_DELETE);
  });
};

const getLastCommentPage = (state, post) => {
  const isMobile = getIsMobile(state);
  const showPerPage = getCommentsPerPage(isMobile);
  const entityCount = getEntityCount(state, 'comments', post._id);
  return getLastPage(entityCount, showPerPage);
};
