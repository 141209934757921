import createAction from '../../common/services/create-action';
import {
  POST_ADD_SOLVED_COMMENT,
  POST_ADD_TOP_COMMENT,
} from '../components/messages/message-types';
import { showMessage } from '../../common/messages/framework/store/message-actions';
import { isExperimentEnabled } from '../selectors/experiments-selectors';
import { EXPERIMENT_MARK_AS_SOLVED } from '../constants/experiments';

export const ADD_TOP_COMMENT_REQUEST = 'post/ADD_TOP_COMMENT_REQUEST';
export const ADD_TOP_COMMENT_SUCCESS = 'post/ADD_TOP_COMMENT_SUCCESS';
export const ADD_TOP_COMMENT_FAILURE = 'post/ADD_TOP_COMMENT_FAILURE';

const addTopCommentRequest = createAction(ADD_TOP_COMMENT_REQUEST);
const addTopCommentSuccess = createAction(ADD_TOP_COMMENT_SUCCESS);
const addTopCommentFailure = createAction(ADD_TOP_COMMENT_FAILURE);

export function addTopComment(postId, commentId) {
  return (dispatch, getState, { request }) => {
    dispatch(addTopCommentRequest());

    const promise = request.post(`/posts/${postId}/top-comment`, { commentId });
    return promise
      .then(post => dispatch(addTopCommentSuccess(post)), () => dispatch(addTopCommentFailure()))
      .then(() => promise);
  };
}

export function addTopCommentAndNotify(postId, commentId) {
  return (dispatch, getState) =>
    dispatch(addTopComment(postId, commentId)).then(() =>
      dispatch(
        showMessage(
          isExperimentEnabled(getState(), EXPERIMENT_MARK_AS_SOLVED)
            ? POST_ADD_SOLVED_COMMENT
            : POST_ADD_TOP_COMMENT,
        ),
      ),
    );
}
