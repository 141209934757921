export const POST_DELETE = 'post-delete';
export const POST_PIN = 'post-pin';
export const POST_UNPIN = 'post-unpin';
export const POST_SUBSCRIBED = 'post-subscribe';
export const POST_UNSUBSCRIBED = 'post-unsubscribe';
export const POST_ENABLE_COMMENTING = 'post-enable-commenting';
export const POST_DISABLE_COMMENTING = 'post-disable-commenting';
export const POST_UPDATE = 'post-update';
export const POST_MOVE = 'post-move';
export const POST_CHANGE_CATEGORY = 'post-category-change';
export const POST_ADD_TOP_COMMENT = 'post-add-top-comment';
export const POST_REMOVE_TOP_COMMENT = 'post-remove-top-comment';
export const POST_ADD_SOLVED_COMMENT = 'post-add-solved-comment';
export const POST_REMOVE_SOLVED_COMMENT = 'post-remove-solved-comment';
export const POST_INVALID = 'post-invalid';

export const COMMENT_UPDATE = 'comment-update';
export const COMMENT_DELETE = 'comment-delete';
export const COMMENT_INVALID = 'comment-invalid';

export const REQUIRE_POST_FIELDS = 'require-post-fields';

export const CATEGORY_DELETE = 'category-delete';
export const CATEGORY_UPDATE = 'category-update';
export const CATEGORY_SUBSCRIBED = 'category-subscribed';
export const CATEGORY_UNSUBSCRIBED = 'category-unsubscribed';
export const CATEGORY_CREATED = 'category-created';
export const CATEGORY_ACCESS_UPDATED = 'category-access-updated';

export const USER_SUBSCRIBED = 'user-subscribe';
export const USER_UNSUBSCRIBED = 'user-unsubscribe';

export const DEMO_MODE_SITE = 'demo-mode-site';
export const DEMO_MODE_EDITOR = 'demo-mode-editor';
export const DEMO_MODE_PREVIEW = 'demo-mode-preview';

export const MAIN_PAGE_UPDATED = 'main-page-updated';
export const ASSETS_ARE_UPLOADING = 'assets-are-uploading';

export const RCE_FILE_DOWNLOAD_WILL_BEGIN_SHORTLY = 'file-download-will-begin-shortly';
export const RCE_FILE_TOO_BIG = 'file-too-big';
export const RCE_FILE_UPLOAD_LIMITED = 'file-upload-limited';
export const RCE_FILE_UPLOAD_FAILURE = 'file-upload-failure';

export const POST_LINK_COPIED = 'post-link-copied';
export const POST_LIKE_SUCCESS = 'post-like-success';
export const COMMENT_INCREMENT_LIKE_COUNT_SUCCESS = 'increment-comment-like-success';
export const COMMENT_CREATE_SUCCESS = 'comment-create-success';
export const POST_CREATE_SUCCESS = 'post-create-success';
export const SEARCH_SEARCH_SUCCESS = 'search-fetch-success';
export const CATEGORY_RANKS_UPDATE_SUCCESS = 'category-ranks-update-success';

export const POST_LIMIT_REACHED = 'post-limit-reached';
