import { findKey } from 'lodash';

export const SEARCH_RESULTS = 'search_results';
export const POST_PAGE = 'post_page';
export const POST_EDIT_PAGE = 'post_edit_page';
export const FEED_PAGE = 'feed_page';
export const CATEGORIES_PAGE = 'categories_page';
export const CREATE_POST = 'create_post';

export const routeMap = {
  [SEARCH_RESULTS]: /^\/search(\/([^/]*?))?\/?$/,
  [CREATE_POST]: /^\/[0-9a-zA-Z-_]+\/create-post\/?/,
  [POST_EDIT_PAGE]: /^\/[0-9a-zA-Z-_]+\/([0-9a-zA-Z-_]+)\/edit\/?/,
  [POST_PAGE]: /^\/[0-9a-zA-Z-_]+\/([0-9a-zA-Z-_]+)\/?/,
  [FEED_PAGE]: /^\/([0-9a-zA-Z-_]+)\/?/,
  [CATEGORIES_PAGE]: /^\/$/,
};

const testRoute = (map, location) => findKey(routeMap, regex => regex.test(location));

const detectRoute = location => {
  const pathname = typeof location === 'object' ? location.pathname : location;
  return testRoute(routeMap, pathname);
};

export default detectRoute;
