import _ from 'lodash';
import { SEND_EVENT, SUBSCRIBE_CHANNEL, UNSUBSCRIBE_CHANNEL } from '../../actions/duplexer-actions';

export default function createDuplexerMiddleware({
  duplexerConnection,
  eventHandlers = {},
  onError = _.noop,
}) {
  const channels = {};

  return store => next => action => {
    const actionHandler = actionHandlers[action.type];
    if (actionHandler) {
      try {
        return actionHandler({
          connection: duplexerConnection,
          store,
          channels,
          eventHandlers,
          channelKey: getChannelKey(action.payload),
          ...action.payload,
        });
      } catch (error) {
        console.error(error);
        onError(error);
      }
    }

    return next(action);
  };
}

const actionHandlers = {
  [SUBSCRIBE_CHANNEL]: ({
    channelName,
    resourceId,
    channelKey,
    store,
    connection,
    eventHandlers,
    channels,
  }) => {
    const channel = connection.subscribe(channelName, { resourceId });
    channels[channelKey] = channel;

    const channelEventHandlers = eventHandlers[channelName] || {};
    Object.keys(channelEventHandlers).forEach(eventName => {
      channel.on(eventName, data => store.dispatch(channelEventHandlers[eventName](data)));
    });

    return channel;
  },
  [UNSUBSCRIBE_CHANNEL]: ({ channelName, resourceId, channelKey, connection, channels }) => {
    connection.unsubscribe(channelName, resourceId);
    delete channels[channelKey];
  },
  [SEND_EVENT]: ({ channelKey, eventName, eventPayload, channels }) => {
    return channels[channelKey].send(eventName, eventPayload);
  },
};

const getChannelKey = ({ channelName, resourceId }) =>
  resourceId ? `${channelName}-${resourceId}` : channelName;
