import {
  SOCKET_TYPING_MESSAGE,
  addTypingUser,
  removeTypingUserDebounced,
} from '../actions/typing-socket';
import { SOCKET_NEW_COMMENT, newComment } from '../actions/comment-socket';
import {
  SOCKET_NEW_POST,
  SOCKET_UPDATE_POST_COUNTERS,
  SOCKET_UPDATE_CATEGORY_COUNTERS,
  newPost,
  updatePostCounters,
  updateCategoryCounters,
} from '../actions/category-socket';
import { WS_EMIT, WS_JOIN, WS_LEAVE } from '../actions/ws-actions';
import noopMiddleware from './noop-middleware';

const channels = {};
const socketActionCreators = {};

const getPayload = cb => {
  return msg => cb(msg.payload);
};

socketActionCreators[SOCKET_TYPING_MESSAGE] = message => dispatch => {
  dispatch(getPayload(removeTypingUserDebounced)(message));
  dispatch(getPayload(addTypingUser)(message));
};
socketActionCreators[SOCKET_NEW_COMMENT] = getPayload(newComment);
socketActionCreators[SOCKET_NEW_POST] = getPayload(newPost);
socketActionCreators[SOCKET_UPDATE_POST_COUNTERS] = getPayload(updatePostCounters);
socketActionCreators[SOCKET_UPDATE_CATEGORY_COUNTERS] = getPayload(updateCategoryCounters);

const handleJoinAction = ({ store, action, channelPrefix }) => wsClient => {
  const payload = {};
  payload.channel = action.payload.channel;
  if (action.payload.channel.indexOf('user-') === -1) {
    payload.channel = `${channelPrefix}${action.payload.channel}`;
  }
  wsClient.queue('join', payload);
  if (channels[payload.channel]) {
    console.warn('WS: Channel already opened');
    return;
  }
  channels[payload.channel] = wsClient.createListener(payload.channel);
  Object.keys(socketActionCreators).forEach(event => {
    channels[payload.channel].on(event, data => {
      store.dispatch(socketActionCreators[event](data));
    });
  });
};

const handleLeaveAction = ({ action, channelPrefix }) => wsClient => {
  const payload = {};
  payload.channel = `${channelPrefix}${action.payload.channel}`;
  if (channels[payload.channel]) {
    channels[payload.channel].destroy();
    delete channels[payload.channel];
  }
};
const handleEmitAction = ({ action, channelPrefix }) => wsClient => {
  const payload = {};
  payload.channel = `${channelPrefix}${action.payload.channel}`;
  payload.eventName = action.payload.eventName;
  payload.payload = action.payload.message;
  wsClient.queue('emit', payload);
};

export default function wsMiddleware(wsClientPromise, instanceId) {
  if (!wsClientPromise) {
    return noopMiddleware;
  }

  let wsClient;
  const pendingActions = [];
  const channelPrefix = `instance-${instanceId}/`;

  wsClientPromise.then(client => {
    wsClient = client;
    pendingActions.forEach(action => action(wsClient));
  });

  const withPendingActions = fn => fnArgs => {
    if (wsClient) {
      fn(fnArgs)(wsClient);
    } else {
      pendingActions.push(fn(fnArgs));
    }
  };

  return store => next => action => {
    switch (action.type) {
      case WS_JOIN:
        return withPendingActions(handleJoinAction)({ store, action, channelPrefix });
      case WS_LEAVE:
        return withPendingActions(handleLeaveAction)({ store, action, channelPrefix });
      case WS_EMIT:
        return withPendingActions(handleEmitAction)({ store, action, channelPrefix });
      default:
        return next(action);
    }
  };
}
