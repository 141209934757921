export const buildPaginationRequestParams = (page, pageSize, pages = 1) => {
  if (page !== undefined) {
    return {
      offset: (page - 1) * pageSize,
      size: pageSize * pages,
    };
  }
  return {};
};

export const getLastPage = (entityCount, pageSize) => {
  return Math.max(Math.ceil(entityCount / pageSize), 1);
};

export const buildPageUrl = (categorySlug, postSlug, page = 1) =>
  page === 1 ? `/${categorySlug}/${postSlug}` : `/${categorySlug}/${postSlug}/p-${page}`;
