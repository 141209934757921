import { get } from 'lodash';
import detectRoute, {
  POST_PAGE,
  CATEGORIES_PAGE,
  FEED_PAGE,
  SEARCH_RESULTS,
  CREATE_POST,
  POST_EDIT_PAGE,
} from '../services/detect-route';
import {
  getReferrer,
  getCurrentOrigin,
  getImageCount,
  getVideoCount,
  getQuoteCount,
  getEmojiCount,
  getMentionCount,
  getHashtagCount,
  getFileCount,
  POST_LIMITER_VALUES,
} from './bi-helpers';
import { ACTIONS_CLOSED } from '../actions/actions-closed';
import { ACTIONS_OPENED } from '../actions/actions-opened';
import { NAVIGATE_WITHIN_FORUM } from '../../common/actions/navigate-within-forum';
import { BUTTON_CLICKED } from '../actions/button-clicked';
import { CHANGE_PAGE } from '../actions/change-page';
import { CREATE_COMMENT_SUCCESS } from '../actions/create-comment';
import { CREATE_POST_REQUEST, CREATE_POST_SUCCESS } from '../actions/create-post';
import { DELETE_POST_SUCCESS } from '../actions/delete-post';
import { FETCH_SEARCH_SUCCESS } from '../actions/fetch-search';
import { INCREMENT_COMMENT_LIKE_COUNT_SUCCESS } from '../actions/increment-comment-like-count';
import { INCREMENT_POST_LIKE_COUNT_SUCCESS } from '../../common/actions/increment-post-like-count';
import { PAGE_OPENED } from '../actions/page-opened';
import { SUBSCRIBE_SUCCESS } from '../actions/subscribe';
import { UNSUBSCRIBE_SUCCESS } from '../actions/unsubscribe';
import { UPDATE_POST_REQUEST, UPDATE_POST_SUCCESS } from '../actions/update-post';
import { UPLOAD_ENDED } from '../actions/upload-ended';
import { UPLOAD_STARTED } from '../actions/upload-started';
import { getCategoryBySlug } from '../selectors/categories-selectors';
import { getLocation } from '../../common/store/location/location-selectors';
import { getPostBySlug } from '../selectors/post-selectors';
import { SET_POSTS_PAGE_SORTING, SET_SEARCH_PAGE_SORTING } from '../actions/set-sorting';
import { getPreviousMatches, getRouteParams } from '../../common/router/router-selectors';
import { isOwner } from '../../common/store/current-user/current-user-selectors';
import { APP_LOADED_RECORDED } from '../../common/store/app-loaded/app-loaded-actions';
import { TYPE_IMAGE } from '../../common/services/wix-media/media-types';
import { FILE_DOWNLOAD_REQUEST, FILE_UPLOADED } from '../actions/file-upload-bi-actions';
import { POST_LIMIT_REACHED } from '../components/messages/message-types';
import { SHOW_MESSAGE } from '../../common/messages/framework/store/message-actions';
import { getPostLimiter } from '../selectors/forum-data-selectors';
import { CLICK_PUBLISH, REPLY_OR_COMMENT_INTENT, SHOW_EMPTY_STATE } from '../actions/user-events';
import { getIsMainPageEnabled } from '../selectors/app-settings-selectors';
import { SET_ROUTER_MATCH } from '../../common/router';

export const eventMap = {
  [APP_LOADED_RECORDED]: (action, state, vsi) => ({
    evid: 704,
    vsi,
  }),
  [SUBSCRIBE_SUCCESS]: (action, state) => {
    switch (action.payload.type) {
      case 'post':
        return {
          evid: 553,
          flag: 1,
          origin: getCurrentOrigin(state),
        };
      case 'category':
        return {
          evid: 554,
          type: 'follow',
          origin: getCurrentOrigin(state),
        };
      default:
        return;
    }
  },
  [UNSUBSCRIBE_SUCCESS]: (action, state) => {
    switch (action.payload.type) {
      case 'post':
        return {
          evid: 553,
          flag: 0,
          origin: getCurrentOrigin(state),
        };
      case 'category':
        return {
          evid: 554,
          type: 'unfollow',
          origin: getCurrentOrigin(state),
        };
      default:
        return;
    }
  },
  [CHANGE_PAGE]: (action, state) => {
    if (get(action.payload, 'meta.bi.buttonType')) {
      return {
        evid: 519,
        page_destination: action.payload.page,
        button_type: action.payload.buttonType,
        origin: getCurrentOrigin(state),
      };
    }
  },
  [FETCH_SEARCH_SUCCESS]: (action, state) => ({
    evid: 511,
    origin: getReferrer(state), //TODO: after release when searching 2nd time in search page it should show search page
    referral_info: 'direct',
    search_term: getRouteParams(state).query,
    result_count_posts: action.payload.posts.length,
    result_count_members: action.payload.users.length,
  }),
  [DELETE_POST_SUCCESS]: (action, state) => ({
    evid: 555,
    post_id: action.payload,
    is_admin: isOwner(state) ? 1 : 0,
  }),
  [SET_ROUTER_MATCH]: (action, state, viewerSessionId, config) => {
    const prevMatches = getPreviousMatches(state);
    const isInitialLoad = prevMatches.length === 0;

    if (isInitialLoad) {
      return;
    }
    const routeParams = action.payload.params;

    const { categorySlug, postSlug } = routeParams;
    const prevMatchParams = prevMatches && prevMatches.length > 0 && prevMatches[0].params;
    if (
      categorySlug &&
      postSlug &&
      prevMatchParams.categorySlug === categorySlug &&
      prevMatchParams.postSlug === postSlug
    )
      return;

    const callerLocation = getLocation(state);
    const path = action.payload.pathname;
    const route = detectRoute(path);
    const origin = getCurrentOrigin(state); // we get previous location, because SET_ROUTER_MATCH action is not yet committed to state, it's in middleware

    if (route === FEED_PAGE) {
      const category = getCategoryBySlug(state, categorySlug);

      if (category) {
        return {
          evid: 502,
          category_id: category._id,
          category_slug: category.slug,
          origin,
          main_display_type: getIsMainPageEnabled(state, config.style)
            ? 'list_of_categories'
            : 'list_of_posts',
        };
      }
    }

    if (route === CREATE_POST) {
      return {
        evid: 505,
      };
    }
    if (callerLocation && route === POST_PAGE) {
      let evid, type;

      //TODO: find out if we need this hash check
      const hash = path.split('#')[1];
      if (hash && hash.includes('comments')) {
        evid = 518;
        type = 'post_comments';
      } else if (hash) {
        evid = 518;
        type = 'comment';
      } else {
        evid = 516;
      }
      const postSlug = routeParams.postSlug;
      const post = getPostBySlug(state, postSlug);
      const search_term = detectRoute(callerLocation) === SEARCH_RESULTS ? routeParams.query : null;

      if (post) {
        return {
          evid,
          type,
          post_id: post._id,
          post_slug: postSlug,
          origin,
          search_term,
        };
      }
    }
  },
  [UPLOAD_STARTED]: (action, state) => {
    if (action.payload.type === TYPE_IMAGE) {
      return {
        evid: 551,
        origin: getCurrentOrigin(state),
      };
    }
  },
  [UPLOAD_ENDED]: (action, state) => {
    if (action.payload.type === TYPE_IMAGE) {
      return {
        evid: 552,
        origin: getCurrentOrigin(state),
      };
    }
  },
  [BUTTON_CLICKED]: (action, state) => {
    const origin = getCurrentOrigin(state);
    switch (action.payload.name) {
      case 'back_to_categories_menu_click':
        return {
          evid: 548,
        };
      case 'back_to_site_click':
        return {
          evid: 547,
          origin,
        };
      case 'search_tab':
        return {
          evid: 536,
          tab_name: action.payload.type,
        };
      case 'action_click':
        return {
          evid: 526,
          origin,
          ...action.payload,
        };
      case 'cancel-promote-moderator':
        return {
          evid: 581,
        };
      case 'cancel-demote-moderator':
        return {
          evid: 583,
        };
      case 'log_in_to_comment':
        return {
          evid: 568,
          type: action.payload.type,
        };
      default:
        console.debug('unmapped event', action);
        return;
    }
  },
  [PAGE_OPENED]: (action, state) => {
    const main_display_type = action.payload.isMainPageEnabled
      ? 'list_of_categories'
      : 'list_of_posts';
    switch (action.payload.page) {
      case POST_PAGE:
        return {
          evid: 517,
          post_id: action.payload.post._id,
          type: action.payload.page,
          post_slug: action.payload.post.title,
          origin: getReferrer(state),
          search_term: getReferrer(state) === SEARCH_RESULTS ? getRouteParams(state).query : null,
        };
      case POST_EDIT_PAGE:
        return {
          evid: 506,
        };
      case CATEGORIES_PAGE:
        return {
          evid: 501,
          origin: getReferrer(state),
          main_display_type,
        };
      case FEED_PAGE:
        return {
          evid: 503,
          origin: getReferrer(state),
          category_id: action.payload.category._id,
          category_slug: action.payload.category.slug,
          main_display_type,
        };
      case SEARCH_RESULTS:
        return {
          evid: 535,
        };
      default:
        console.debug('unmapped event', action);
        return;
    }
  },
  [CREATE_POST_SUCCESS]: (action, state) => createEditPost(action, state, 'post', false),
  [UPDATE_POST_SUCCESS]: (action, state) => createEditPost(action, state, 'post', true),
  [CREATE_COMMENT_SUCCESS]: (action, state) =>
    createEditPost(action, state, get(action, 'meta.isReply', false) ? 'reply' : 'comment', false),
  [INCREMENT_COMMENT_LIKE_COUNT_SUCCESS]: (action, state) => ({
    evid: 521,
    post_id: action.payload._id,
    type: 'comment',
    flag: action.payload.isLiked ? 1 : 0,
    origin: getCurrentOrigin(state),
  }),
  [INCREMENT_POST_LIKE_COUNT_SUCCESS]: (action, state) => ({
    evid: 521,
    post_id: action.payload._id,
    type: 'post',
    flag: action.payload.isLiked ? 1 : 0,
    origin: getCurrentOrigin(state),
  }),
  [UPDATE_POST_REQUEST]: () => ({
    evid: 508,
  }),
  [CREATE_POST_REQUEST]: () => ({
    evid: 508,
  }),
  [ACTIONS_OPENED]: (action, state) => ({
    evid: 524,
    type: action.payload.type,
    origin: getCurrentOrigin(state),
  }),
  [ACTIONS_CLOSED]: (action, state) => ({
    evid: 525,
    type: action.payload.type,
    origin: getCurrentOrigin(state),
  }),
  [SET_POSTS_PAGE_SORTING]: (action, state) => {
    const categorySlug = getRouteParams(state).categorySlug;
    const category = getCategoryBySlug(state, categorySlug);

    return {
      evid: 566,
      sort_by: action.payload,
      category_id: category._id,
      origin: 'posts_page',
      application_type: 'web',
    };
  },
  [SET_SEARCH_PAGE_SORTING]: action => {
    return {
      evid: 566,
      sort_by: action.payload,
      category_id: null,
      origin: 'search',
      application_type: 'web',
    };
  },

  [FILE_UPLOADED]: action => ({
    evid: 571,
    application_type: 'web',
    file_id: action.payload.fileId,
    file_ext: action.payload.fileExtension,
    mime_type: action.payload.mimeType,
    file_size: action.payload.size,
    type: action.payload.origin,
    success: action.payload.isSuccessful ? 1 : 0,
  }),
  [FILE_DOWNLOAD_REQUEST]: action => ({
    evid: 572,
    application_type: 'web',
    file_id: action.payload.fileId,
    file_ext: action.payload.fileExtension,
    mime_type: action.payload.mimeType,
    file_size: action.payload.size,
    type: action.payload.origin,
    success: action.payload.isSuccessful ? 1 : 0,
  }),
  [SHOW_MESSAGE]: (action, state) => {
    switch (action.payload.type) {
      case POST_LIMIT_REACHED:
        return {
          evid: 576,
          application_type: 'web',
          post_limit: POST_LIMITER_VALUES[getPostLimiter(state)] || 10,
        };
      default:
        return;
    }
  },
  [SHOW_EMPTY_STATE]: action => ({
    evid: 578,
    empty_state_type: action.payload.type,
    category_id: action.payload.categoryId,
  }),
  [CLICK_PUBLISH]: action => ({
    evid: 587,
    type: action.payload.type,
    symbol_count: action.payload.symbolCount,
  }),
  [REPLY_OR_COMMENT_INTENT]: action => ({
    evid: 588,
    type: action.payload,
  }),
};

function createEditPost(action, state, type, isEdit) {
  const entity = action.payload;
  const emojiCount = getEmojiCount(entity);
  const mentionCount = getMentionCount(entity);
  const imageCount = getImageCount(entity);
  const fileCount = getFileCount(entity);
  const quoteCount = getQuoteCount(entity);
  const videoCount = getVideoCount(entity);
  const hashtagCount = getHashtagCount(entity);
  return {
    application_type: 'web',
    evid: 523,
    post_id: entity._id,
    type,
    is_edited: Number(isEdit),
    has_emoji: emojiCount > 0 ? 1 : 0,
    has_image: imageCount > 0 ? 1 : 0,
    has_mention: mentionCount > 0 ? 1 : 0,
    image_count: imageCount,
    file_count: fileCount,
    has_video: videoCount > 0 ? 1 : 0,
    has_quote: quoteCount > 0 ? 1 : 0,
    has_hashtag: hashtagCount > 0 ? 1 : 0,
    hashtag_count: hashtagCount,
    is_admin: isOwner(state) ? 1 : 0,
  };
}

export const endpoint = 'forums-uou';
export const src = 69;
