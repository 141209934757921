import { pick } from 'lodash';
import { MODAL_TYPE_EDIT_POST } from '../components/modals/post-create-modal/post-edit-modal-type';
import sanitizeContent from '../services/sanitize-content';
import { extractHashtagsFromContent } from '../services/hashtag-utils';
import { extractMentionsFromContent } from '../services/mentions-services';
import { POST_UPDATE } from '../components/messages/message-types';
import createAction from '../../common/services/create-action';
import { showMessage } from '../../common/messages/framework/store/message-actions';
import { navigateWithinForum } from '../../common/actions/navigate-within-forum';
import { getRouteParams } from '../../common/router/router-selectors';
import { closeModal } from '../../common/modals/framework/store/modal-actions';
import { createPromisifiedAction } from '../../common/actions-promisifier/create-promisified-action';

export const UPDATE_POST_REQUEST = 'post/UPDATE_REQUEST';
export const UPDATE_POST_SUCCESS = 'post/UPDATE_SUCCESS';
export const UPDATE_POST_FAILURE = 'post/UPDATE_FAILURE';

export const updatePostRequest = createAction(UPDATE_POST_REQUEST);
export const updatePostSuccess = createAction(UPDATE_POST_SUCCESS);
export const updatePostFailure = createAction(UPDATE_POST_FAILURE, () => undefined, meta => meta);

const PROPS = ['title', 'content'];

export function updatePost(post = {}) {
  return (dispatch, getState, { request }) => {
    dispatch(updatePostRequest());

    const updatedPostSanitized = sanitizeContent(pick(post, PROPS));
    const promise = request.patch(`/posts/${post._id}`, {
      ...updatedPostSanitized,
      hashtags: extractHashtagsFromContent(updatedPostSanitized.content),
      mentions: extractMentionsFromContent(updatedPostSanitized.content),
    });

    return promise
      .then(
        updatedPost => {
          dispatch(updatePostSuccess(updatedPost));
          dispatch(showMessage(POST_UPDATE));
          const params = getRouteParams(getState());
          dispatch(closeModal({ type: MODAL_TYPE_EDIT_POST, resolve: false }));
          dispatch(navigateWithinForum(`/${params.categorySlug}/${params.postSlug}`));
        },
        response => dispatch(updatePostFailure(response)),
      )
      .then(() => promise);
  };
}

export const updatePostPromisified = createPromisifiedAction(
  updatePost,
  () => null,
  response => response.status,
);
